import {
  createContext, ReactNode, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { VisitModeType } from '../enums';
import {
  saveDataPoints,
  useDeleteProgressNoteMutation,
  useFetchProgressNotesQuery,
  useSaveProgressNoteMutation,
} from '../api/esourceService';
import ProgressNoteStatus from '../enums/ProgressNoteStatus';
import {
  DataPoint, ProgressNoteInterface, SubjectData, VisitInterface,
} from '../types';
import { getAncillaryDataPoint } from '../util/dataPointUtil';
import DeleteProgressNoteDialog from '../components/Procedure/Dialog/DeleteProgressNoteDialog';
import SaveProgressNoteErrorDialog from '../components/Procedure/Dialog/SaveProgressNoteErrorDialog';
import BlurOverlay from '../components/BlurOverlay';

interface ProgressNoteValues {
  progressNotes: ProgressNoteInterface[],
  isLoading: boolean,
  handleCreateProgressNote: (status: ProgressNoteStatus, note: string, sendSynthetic: boolean, successCallback?: Function) => Promise<void>,
  handleUpdateProgressNote: (progressNoteToEdit: ProgressNoteInterface, newNote?: string, newStatus?: ProgressNoteStatus, successCallback?: Function) => Promise<void>,
  handleDeleteProgressNote: (progressNoteToDelete: ProgressNoteInterface, successCallback?: Function) => void,
}

export interface ProgressNoteContextProviderProps {
  procedureId?: string,
  visitMode?: VisitModeType,
  children: ReactNode,
  showDataPointSaveError?: Function,
  procedureName?: string,
  subjectData: SubjectData,
  visitConfig: VisitInterface,
}

interface DeleteProgressNoteDialogInterface {
  isOpen: boolean,
  continueHandler?: Function,
  closeHandler?: Function,
}

const ProgressNoteContext = createContext<ProgressNoteValues | Record<string, never>>({});
export default ProgressNoteContext;

export function ProgressNoteContextProvider(props: ProgressNoteContextProviderProps) {
  const {
    procedureId, visitMode, children, procedureName, subjectData, visitConfig,
  } = props;
  const { studyId, subjectId, visitId } = useParams();
  const { currentUser } = getAuth();
  const [deleteProgressNoteDialogValues, setDeleteProgressNoteDialogValues] = useState<DeleteProgressNoteDialogInterface>({ isOpen: false });
  const { progressNotes, isLoading, refetch } = useFetchProgressNotesQuery(visitMode, studyId!, subjectId, visitId, procedureId);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveProgressNoteError, setSaveProgressNoteError] = useState<string | undefined>(undefined);
  const saveMutation = useSaveProgressNoteMutation();
  const deleteMutation = useDeleteProgressNoteMutation();
  const isFirstNoteAndRegularVisit = (!progressNotes || progressNotes.length === 0) && visitMode === VisitModeType.DEFAULT;

  const sendSyntheticDataPoint = async () => {
    const syntheticDataPoint: DataPoint = getAncillaryDataPoint(studyId!, procedureId, currentUser, subjectData, visitConfig);
    await saveDataPoints({
      dataPointsToSave: [syntheticDataPoint],
      studyId: studyId!,
      visitId,
      subjectId,
      procedureId,
    });
  };

  const handleCreateProgressNote = async (status: ProgressNoteStatus, note: string, sendSynthetic: boolean, successCallback?: Function) => {
    const { name: visitName } = visitConfig;
    const newProgressNote: ProgressNoteInterface = {
      procedure_id: procedureId!,
      note,
      status,
      user_id: currentUser!.uid!,
      user_name: currentUser!.displayName || '',
      visit_name: visitName,
      procedure_name: procedureName,
    } as ProgressNoteInterface;

    if (isSaving) return;
    setIsSaving(true);

    try {
      if (sendSynthetic && isFirstNoteAndRegularVisit) {
        await sendSyntheticDataPoint();
      }

      await saveMutation.mutateAsync(
        {
          studyId: studyId!,
          subjectId: subjectId!,
          visitId: visitId!,
          procedureId: procedureId!,
          visitMode,
          progressNote: newProgressNote,
        },
      );

      await refetch();
      if (successCallback) {
        await successCallback();
      }
    } catch (error) {
      setSaveProgressNoteError(note);

      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateProgressNote = async (progressNoteToEdit: ProgressNoteInterface, newNote?: string, newStatus?: ProgressNoteStatus, successCallback?: Function) => {
    const { name: visitName } = visitConfig;
    const progressNote = {
      ...progressNoteToEdit,
      visit_name: visitName,
      procedure_name: procedureName,
    };
    if (newNote) {
      progressNote.note = newNote;
    }
    if (newStatus) {
      progressNote.status = newStatus;
    }
    if (procedureId && !progressNote.procedure_id) {
      progressNote.procedure_id = procedureId;
    }

    if (isSaving) return;
    setIsSaving(true);

    try {
      await saveMutation.mutateAsync(
        {
          studyId: studyId!,
          subjectId: subjectId!,
          visitId: visitId!,
          procedureId: procedureId!,
          visitMode,
          progressNote,
        },
      );

      await refetch();
      if (successCallback) {
        await successCallback();
      }
    } catch (error) {
      setSaveProgressNoteError(newNote);

      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteProgressNote = (progressNoteToDelete: ProgressNoteInterface, successCallback?: Function) => {
    const performDeleteProgressNote = async () => {
      // We don't need an isSaving check here because the delete modal will immediately close
      // when we click the delete button thus it isn't possible to spam click
      setIsSaving(true);

      try {
        await deleteMutation.mutateAsync(
          {
            studyId: studyId!,
            subjectId: subjectId!,
            visitMode,
            progressNoteExternalId: progressNoteToDelete.external_id,
          },
        );

        await refetch();
        if (successCallback) {
          await successCallback();
        }
      } catch (error) {
        setSaveProgressNoteError(progressNoteToDelete.note);
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsSaving(false);
      }
    };

    setDeleteProgressNoteDialogValues({
      isOpen: true,
      continueHandler: performDeleteProgressNote,
    });
  };

  const value = useMemo(
    () => ({
      progressNotes,
      isLoading,
      handleCreateProgressNote,
      handleUpdateProgressNote,
      handleDeleteProgressNote,
    }),
    [progressNotes, isLoading, isSaving],
  );

  return (
    <ProgressNoteContext.Provider value={value}>
      {children}
      {isSaving && <BlurOverlay />}
      <DeleteProgressNoteDialog
        {...deleteProgressNoteDialogValues}
        closeHandler={() => setDeleteProgressNoteDialogValues((prevState) => ({ ...prevState, isOpen: false }))}
      />
      {saveProgressNoteError
        && (
          <SaveProgressNoteErrorDialog
            open
            progressNote={saveProgressNoteError}
            closeHandler={() => setSaveProgressNoteError(undefined)}
          />
        )}
    </ProgressNoteContext.Provider>
  );
}

ProgressNoteContextProvider.defaultProps = {
  procedureId: '',
  visitMode: VisitModeType.SANDBOX,
  showDataPointSaveError: () => {
  },
};
