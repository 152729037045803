import {
  Fragment, memo, useCallback, useContext, useEffect, useState,
} from 'react';
import objectHash from 'object-hash';
import { useTranslation } from 'react-i18next';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import {
  faCaretDown, faCaretUp, faFileLines, faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { CrioDivider, CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { CrioLoadingButton } from '@crio/crio-react-component/dist/cjs/components/Inputs';
import { ProcedureQuestion } from './ProcedureQuestion';
import generateUids from '../../util/UidUtil';
import ProcedureContext, { AnswerChangeOptions } from '../../context/ProcedureContext';
import { DataPoint, QuestionInterface } from '../../types';
import {
  AnswerType, CarryForwardType, NormalQuestionType, SyntheticQuestionType, VisitModeType, isSyntheticQuestionType,
} from '../../enums';
import { ProcedureRecord } from '../../context/types';
import Row from '../Row';
import { getDataPointsFromProcedureRecords } from '../../context/util';
import { formatDateTimeWithTranslation } from '../../util/dateTimeUtil';

const MultiRecordTable = styled(Table)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]} !important;

  &.Closed {
    display: none;
  }
`;

const MultiRecordHeaderColumn = styled(TableCell)`
  font-family: ${(props) => props.theme.typography.fontFamily} !important;
  background-color: ${(props) => props.theme.palette.grey[200]} !important;
  color: ${(props) => props.theme.palette.grey[900]};
  font-size: inherit;
  font-weight: 500;
  padding: 10px 25px !important;
`;

const MultiRecordRow = styled(TableRow)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-bottom: none;
  border-top: none;
  background-color: ${(props) => props.theme.palette.grey.A100};

  .Instruction {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }

  &:nth-child(odd) {
    .MuiTableCell-root {
      cursor: pointer;
    }
  }

  &.Closed {
    display: none;
  }

  &.Expanded-record {
    .MuiTableCell-root {
      color: #333333;
    }
  }

  &.Opened {
    .MuiTableCell-root {
      border-bottom: none;
    }
  }

  .MuiTableCell-root {
    font-size: inherit;
    font-family: "Poppins Light", "Poppins", sans-serif;
    padding: 12px 25px;
    font-weight: 300;
    color: ${(props) => props.theme.palette.grey[900]};

    &.ExpandedRecordNumber {
      font-weight: 500;
      padding-right: 0;
      padding-bottom: 0;

      .ExpandedRecordHeader {
        padding-bottom: 12px;
        border-bottom: 1px dotted ${(props) => props.theme.palette.grey[500]};
      }
    }

    &.ExpandedRecordCaret {
      padding-left: 0;
      padding-bottom: 0;
      color: ${(props) => props.theme.palette.grey[700]};

      .ExpandedRecordHeader {
        padding-bottom: 12px;
        border-bottom: 1px dotted ${(props) => props.theme.palette.grey[500]};
        padding-left: 25px;
      }
    }

    .Question:first-of-type {
      padding-top: 15px;
    }
  }

  .caret-icon {
    text-align: right;
  }

  .MultiRecordButtonsRow {
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 18px 0;

    .ClearAndDeleteBtns {
      .LinkButton {
        color: ${(props) => props.theme.palette.grey[600]};
        font-weight: 400;
        font-family: "Poppins-Regular", "Poppins", sans-serif;
        cursor: pointer;
        padding: 7px 0 7px 20px;
        text-align: center;

        &:hover {
          color: ${(props) => props.theme.palette.error.main};
          text-decoration: underline;
        }

        &.clearButton:hover {
          color: ${(props) => props.theme.palette.linkText.main};
        }

        &:first-of-type {
          border-right: 1px dotted ${(props) => props.theme.palette.grey[500]};
          padding-right: 20px;
        }
      }
    }
  }
`;

const MultiRecordProcedureButtons = styled(Row)`
  padding-top: 30px;
  align-items: center;

  .LinkButton {
    color: ${(props) => props.theme.palette.linkText.main};
    cursor: pointer;
    padding: 7px 0 7px 20px;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LastSaveText = styled(Row)`
  padding: 12px 0px;
  text-align: center;
  font-size: 0.8em;
  font-style: italic;
  color: ${(props) => props.theme.palette.grey[600]};
`;

interface StyledTableContainerProps {
  isPerm: boolean,
  isOnLogsPage: boolean,
}

const StyledDiv = styled.div<StyledTableContainerProps>`
  .RecordsAlreadyExistWrap {
    display: inline-block;
  }

  .RecordsAlreadyExistWarning + div[role=tooltip] {
    top: -30px !important;
  }

  .PermDivider {
    display: ${(props) => (props.isPerm && !props.isOnLogsPage ? 'flex' : 'none')};
    text-wrap: nowrap;
    justify-content: space-between;
    color: #4a0d14;
    padding-bottom: 25px;

    hr {
      width: 50%;
      color: ${(props) => props.theme.palette.text.secondary};
      border-top: 1px dotted;
    }

    span {
      padding: 0 20px 0 0;
      font-weight: 400;
    }

    .fa-file-lines {
      padding: 0 10px 0 20px;
    }
  }

  .TableHeader {
    th {
      background-color: ${(props) => (props.isPerm ? '#4a0d14' : props.theme.palette.grey[200])} !important;
      color: ${(props) => (props.isPerm ? props.theme.palette.primary.contrastText : '#1f1f1f')};
    }
  }

  .permanentLogsExternalWarning {
    display: ${(props) => (props.isPerm && !props.isOnLogsPage ? 'table-row' : 'none')};

    th {
      font-family: "Poppins-LightItalic", "Poppins Light Italic", "Poppins", sans-serif;
      border-bottom: 1px solid #764248;
      font-style: italic;
      font-weight: 300;
      padding: 10px 12px !important;

      .fa-circle-info {
        padding-right: 10px;
        color: #dcd0d0;
      }
    }
  }
`;

/**
 * Get the base number of columns to span based on the given questions
 * @param questions  Array<QuestionInterface> to base the number of columns on
 * @return           number of columns that each should span
 */
const getBaseColspan = (questions: Array<QuestionInterface>): number => questions
  .filter((question: QuestionInterface) => !isSyntheticQuestionType(question.type))
  .slice(0, 4).length + 1;

interface RecordProps {
  readOnly: boolean,
  recordUid: string,
  questions: Array<QuestionInterface>,
  record: ProcedureRecord,
  recordNum: number,
  isLoading: boolean,
  expanded: boolean,
  onSaveCallback: Function,
}

interface RecordPreviewProps {
  recordUid: string,
  questions: Array<QuestionInterface>,
  record: ProcedureRecord,
  recordNum: number,
  expanded: boolean,
  setExpanded: Function,
  // We memoize the preview which means if the props don't change, then the preview
  // doesn't change. Memo is not good at looking into nested objects which is what
  // the record is, so pass the hash in to more accurately determine when to rerender
  recordHash: string,
}

interface ExpandedRecordsInterface {
  [recordId: string]: boolean,
}

const RecordPreview = memo(({
  recordUid, expanded, recordNum, questions, setExpanded, record, recordHash,
}: RecordPreviewProps) => {
  const { t } = useTranslation();
  return (
    <MultiRecordRow data-hash={recordHash} data-testid={`multirecordRow-${recordNum}`} key={`collapsed:${recordUid}`} className={`RecordHeader${expanded ? ' Opened' : ''}`} onClick={() => setExpanded(!expanded, recordUid)}>
      {expanded
        ? (
          <>
            <TableCell colSpan={getBaseColspan(questions)} className="ExpandedRecordNumber">
              <div className="ExpandedRecordHeader">
                {t('Procedure.MultiRecord.Record')}
                {' '}
                {recordNum}
              </div>
            </TableCell>
            <TableCell colSpan={1} className="ExpandedRecordCaret caret-icon">
              <div className="ExpandedRecordHeader"><FontAwesomeIcon icon={faCaretUp as IconProp} /></div>
            </TableCell>
          </>
        )
        : (
          <>
            <TableCell>
              {recordNum}
            </TableCell>
            {
              questions.filter(({ type }) => !isSyntheticQuestionType(type)).slice(0, 4).map(
                ({ questionId }: QuestionInterface) => {
                  const { answer, is_disabled } = record[questionId];
                  return <TableCell key={`answerPreview:${recordUid}:${questionId}`}>{!is_disabled ? answer : ''}</TableCell>;
                },
              )
            }
            <TableCell className="caret-icon">
              <FontAwesomeIcon icon={faCaretDown as IconProp} />
            </TableCell>
          </>
        )}
    </MultiRecordRow>
  );
});

/**
 * Record component that handles rendering a Record component
 * @param questions
 * @param recordNum
 * @param recordUid
 * @param handleDelete
 * @constructor
 */
export function Record({
  questions,
  readOnly,
  recordUid,
  record,
  recordNum,
  isLoading,
  expanded,
  onSaveCallback,
}: RecordProps) {
  const { t } = useTranslation();
  const {
    handleAnswerChange: handleAnswerChangeInContext, clearRecord, deleteRecord, saveProcedure, visitMode,
  } = useContext(ProcedureContext);

  if (isLoading) {
    return null;
  }

  return (
    <MultiRecordRow
      data-testid={`multirecordContent-${recordNum}`}
      className={expanded ? 'Expanded-record' : 'Closed'}
      key={recordUid}
    >
      <TableCell colSpan={getBaseColspan(questions) + 1}>
        {questions.map((question: QuestionInterface) => {
          /*
          Anonymous functions will always get a new reference on each rerender, therefore triggering a rerender
          of any children regardless of if they're memoized or not. Memoize the function itself with useCallback
          as we don't want EVERY ProcedureQuestion to rerender on every change. The dependency array is dataPoint.
          This because we actually DO need a new reference when the specific datapoint changes or else the state
          inside the handleAnswerChangeInContext closure will contain stale state.
          */
          const dataPoint = record[question.questionId];
          const handleAnswerChange = useCallback((args: any) => {
            handleAnswerChangeInContext(args);
          }, [dataPoint]);

          // Don't bother rendering everything if it isn't even visible
          if (!expanded) {
            return null;
          }

          return (
            <ProcedureQuestion
              key={`question:${recordUid}:${question.questionId}`}
              showVariableName={visitMode === VisitModeType.PROCEDURE_PREVIEW}
              isLoading={false}
              dataPoint={dataPoint}
              handleAnswerChange={handleAnswerChange}
              {...question}
              recordId={recordUid}
              readOnly={readOnly}
            />
          );
        })}
        <Row className="MultiRecordButtonsRow">
          <CrioButton
            disabled={readOnly}
            theme="Primary"
            onClick={() => saveProcedure({ successCallback: () => onSaveCallback() })}
          >
            {t('Common.Save')}
          </CrioButton>
          {!readOnly && (
            <Row className="ClearAndDeleteBtns">
              <div
                role="button"
                tabIndex={0}
                className="LinkButton clearButton"
                onClick={() => clearRecord(recordUid)}
                onKeyDown={() => clearRecord(recordUid)}
              >
                {t('Common.Clear')}
              </div>
              <div
                role="button"
                tabIndex={0}
                className="LinkButton"
                onClick={() => deleteRecord(recordUid)}
                onKeyDown={() => deleteRecord(recordUid)}
              >
                {t('Common.Delete')}
              </div>
            </Row>
          )}
        </Row>
      </TableCell>
    </MultiRecordRow>
  );
}

function SyntheticQuestion({
  dataPoint,
  readOnly,
}: { dataPoint?: DataPoint, readOnly: boolean }) {
  const { t } = useTranslation();
  const {
    isLoading,
    noEntryText,
    records,
    handleAnswerChange,
  } = useContext(ProcedureContext);

  if (!dataPoint) return null;
  const {
    answer,
    answer_type,
    question_name,
  } = dataPoint;
  const questionType = SyntheticQuestionType[dataPoint.answer_type as keyof typeof SyntheticQuestionType];
  const positiveAnswer = 'Yes';
  const negativeAnswer = answer_type === AnswerType.NO_ENTRY && noEntryText ? `${noEntryText} (No)` : 'No';
  const isNoEntry = questionType === SyntheticQuestionType.NO_ENTRY;
  const hasExistingRecords = getDataPointsFromProcedureRecords(records)
    // Don't care about synthetic questions on the procedure itself or unsaved answers
    .filter(({
      answer_id,
      record_id,
    }) => !!answer_id && !!record_id)
    .length > 0;
  const component = (
    <div data-testid={isNoEntry ? SyntheticQuestionType.NO_ENTRY : SyntheticQuestionType.HAS_CHANGES}>
      <ProcedureQuestion
        dataPoint={{
          ...dataPoint,
          answer: (() => {
            if (answer === '0') return negativeAnswer;
            if (answer === '1') return positiveAnswer;
            return answer;
          })(),
        }}
        handleAnswerChange={(answerChange: AnswerChangeOptions) => {
          const { newDataPoint } = answerChange;
          const revisedDataPoint = {
            ...newDataPoint,
            answer_type,
            answer: (() => {
              if (newDataPoint.answer === positiveAnswer) return '1';
              if (newDataPoint.answer === negativeAnswer) return '0';
              return undefined;
            })(),
          };
          handleAnswerChange({
            ...answerChange,
            newDataPoint: revisedDataPoint,
          });
        }}
        answerOptions={[
          { text: positiveAnswer },
          { text: negativeAnswer },
        ]}
        isLoading={isLoading}
        questionText={isNoEntry ? t('Procedure.MultiRecord.Are there records') : question_name}
        questionId={questionType}
        order={-1}
        readOnly={readOnly || (isNoEntry && hasExistingRecords)}
        type={NormalQuestionType.SINGLE_SELECT}
      />
    </div>
  );

  if ((isNoEntry && hasExistingRecords)) {
    return (
      <div className="RecordsAlreadyExistWrap">
        <CrioTooltip
          className="RecordsAlreadyExistWarning"
          type="CLICK"
          title={t('Procedure.MultiRecord.Answer Cannot Be Modified')}
        >
          {component}
        </CrioTooltip>
      </div>
    );
  }
  return component;
}

SyntheticQuestion.defaultProps = {
  dataPoint: null,
};

export type MultirecordProps = {
  timeZone?: string,
};

/**
 * Multirecord component that handles rendering multiple Record components.
 * @constructor
 * @param props
 */
export default function MultiRecord({ timeZone }: MultirecordProps) {
  const translation = useTranslation();
  const { t } = translation;
  const [expandedRecords, setExpandedRecords] = useState<ExpandedRecordsInterface>({});
  const {
    carryForwardType,
    isLoading,
    isSaving,
    procedureId,
    questions,
    readOnly,
    records,
    rulesProcessing,
    addRecord,
    findSyntheticDataPoint,
    proceedToNextProcedure,
    saveProcedure,
  } = useContext(ProcedureContext);
  const { visitId } = useParams();
  const isOnLogsPage = !visitId;
  const isPerm = carryForwardType === CarryForwardType.PERMANENT;
  const noEntryDataPoint = findSyntheticDataPoint(SyntheticQuestionType.NO_ENTRY);
  const hasChangesDataPoint = findSyntheticDataPoint(SyntheticQuestionType.HAS_CHANGES);
  const yesThereAreRecords = noEntryDataPoint?.answer === '1';

  const hasChangesDataPointIsAnswered = hasChangesDataPoint?.answer === '1';
  const isUneditable: boolean = readOnly || (!!hasChangesDataPoint && !hasChangesDataPointIsAnswered); // perm procedures by default are readonly
  const [numRecords, setNumRecords] = useState<number>(0);
  const [lastSavedDate, setLastSavedDate] = useState<number | undefined>(undefined);

  useEffect(() => {
    const existingMultiRecords = Object.keys(records)
      .filter((recordId) => recordId !== procedureId);
    const noExistingMultiRecords = !existingMultiRecords || existingMultiRecords.length === 0;
    const { answer: noEntryAnswer } = noEntryDataPoint || {};
    if (noExistingMultiRecords && noEntryAnswer === '1' && !isLoading) {
      // Adding the first record if they selected that there are records
      const newRecordId = generateUids(1)[0];
      addRecord(newRecordId, true);
      setExpandedRecords({ [newRecordId]: true });
    }
    setNumRecords(Object.keys(records)
      .filter((recordId) => recordId !== procedureId).length);
  }, [isLoading, records]);

  useEffect(() => {
    if (numRecords === 0) return;
    const newNumRecords = Object.keys(records)
      .filter((recordId) => recordId !== procedureId).length;
    const lastRecordId = Object.keys(records)[newNumRecords];
    if (
      // if we added a new record
      (newNumRecords > numRecords && !isUneditable)
      // if we checked "Yes", has changes or "No", has no changes
      || (newNumRecords === numRecords)
    ) {
      setExpandedRecords((prevExpandedRecords) => {
        const newExpandedRecords = { ...prevExpandedRecords };
        newExpandedRecords[lastRecordId] = !isUneditable;
        return newExpandedRecords;
      });
    }
    setNumRecords(newNumRecords);
  }, [isUneditable, Object.keys(records).length]);

  const setExpandedCallback = useCallback(((expand: boolean, id: string) => {
    setExpandedRecords((prevExpandedRecords) => {
      const newExpandedRecords = { ...prevExpandedRecords };
      newExpandedRecords[id] = expand;
      return newExpandedRecords;
    });
  }), [records.length]);

  if (isLoading) return null;

  // Collapse the records and render the last modified time
  const handleSaveAndStay = () => {
    setLastSavedDate(new Date().getTime());
    setExpandedRecords({});
  };

  const createRecord = (id: string, index: number) => {
    const filteredRecord = records[id].record;
    const expanded = expandedRecords[id];

    return (
      <Fragment key={id}>
        <RecordPreview
          recordUid={id}
          recordNum={index + 1}
          expanded={!!expanded}
          record={filteredRecord}
          setExpanded={setExpandedCallback}
          questions={questions}
          key={`preview:${id}`}
          recordHash={objectHash(filteredRecord)}
        />
        {expanded && (
          <Record
            recordUid={id}
            recordNum={index + 1}
            questions={questions}
            readOnly={isUneditable}
            record={filteredRecord}
            isLoading={isLoading}
            expanded={expandedRecords[id] || false}
            onSaveCallback={handleSaveAndStay}
            key={`record:${id}`}
          />
        )}
      </Fragment>
    );
  };

  const handleAddAnotherRecord = () => {
    setExpandedRecords({});
    if (yesThereAreRecords) {
      addRecord(generateUids(1)[0]);
    }
  };

  return (
    <StyledDiv isPerm={isPerm} isOnLogsPage={isOnLogsPage}>
      <TableContainer>
        <div>
          <SyntheticQuestion dataPoint={hasChangesDataPoint} readOnly={readOnly} />
        </div>

        <Row className="PermDivider">
          <CrioDivider />
          <FontAwesomeIcon icon={faFileLines} size="lg" />
          <span>{t('Procedure.MultiRecord.SUBJECT LOGS')}</span>
          <CrioDivider />
        </Row>

        <div>
          <SyntheticQuestion dataPoint={noEntryDataPoint} readOnly={readOnly || isUneditable} />
        </div>

        {numRecords > 0 && (
          <MultiRecordTable className={!yesThereAreRecords ? 'Closed' : ''}>
            <TableHead className="Hideable">
              <TableRow data-testid="permanentLogsExternalWarning" className="TableHeader permanentLogsExternalWarning">
                <TableCell colSpan={6}>
                  <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                  {t('Procedure.MultiRecord.Modifying records below will not affect the status of this procedure')}
                </TableCell>
              </TableRow>
              <TableRow className="TableHeader">
                <MultiRecordHeaderColumn>#</MultiRecordHeaderColumn>
                {
                  questions.filter(({ type }) => !isSyntheticQuestionType(type))
                    .slice(0, 4)
                    .map(
                      ({
                        questionId,
                        questionText,
                      }: QuestionInterface) => (
                        <MultiRecordHeaderColumn
                          key={questionId}
                        >
                          {questionText}
                        </MultiRecordHeaderColumn>
                      ),
                    )
                }
                <MultiRecordHeaderColumn />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(records)
                .filter((recordId) => recordId !== procedureId)
                .map((id, index) => createRecord(id, index))}
            </TableBody>
          </MultiRecordTable>
        )}

        <MultiRecordProcedureButtons>
          {(() => {
            if (readOnly) {
              if (isOnLogsPage) {
                return null;
              }
              return <CrioButton onClick={() => proceedToNextProcedure()}>{t('Common.Continue')}</CrioButton>;
            }

            if (isOnLogsPage) {
              return (
                <CrioLoadingButton
                  loading={isSaving}
                  onClick={() => {
                    saveProcedure({
                      successCallback: handleSaveAndStay,
                    });
                  }}
                >
                  {t('Common.Finish')}
                </CrioLoadingButton>
              );
            }
            return (
              <CrioButton onClick={() => {
                saveProcedure({ successCallback: proceedToNextProcedure });
              }}
              >
                {t('Common.Save and Continue')}
              </CrioButton>
            );
          })()}
          {!!rulesProcessing && <span data-testid="rulesProcessing" />}
          {(!isUneditable && yesThereAreRecords)
            && (
              <div
                role="button"
                tabIndex={0}
                className="LinkButton"
                onClick={handleAddAnotherRecord}
                onKeyDown={handleAddAnotherRecord}
              >
                <FontAwesomeIcon icon={faPlusCircle as IconProp} />
                {' '}
                {t('Procedure.MultiRecord.Add Another Record')}
              </div>
            )}
        </MultiRecordProcedureButtons>
        {!!lastSavedDate
          && <LastSaveText>{`Saved on ${formatDateTimeWithTranslation(lastSavedDate, translation, timeZone)}`}</LastSaveText>}
      </TableContainer>
    </StyledDiv>
  );
}

MultiRecord.defaultProps = {
  timeZone: 'US/Eastern',
};
