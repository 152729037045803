import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Dispatch, SetStateAction, useEffect, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import {
  DataPoint, Lock, ProcedureStatusInterface, SubjectData, VisitInterface, InitialAlertContent,
} from '../../types';
import {
  CarryForwardType, ProcedureDisplayType, ProcedureStatus, VisitModeType,
  isAnyProcedureMode,
  isAnyReadOnlyMode,
} from '../../enums';
import { Column, Row } from '..';
import Grid from './Grid';
import InstructionText from './InstructionText';
import spinner from '../../assets/spinner.gif';
import MultiRecord from './MultiRecord';
import NormalProcedure from './NormalProcedure';
import { ProcedureContextProvider } from '../../context/ProcedureContext';
import Slideout from '../Visit/Slideout';
import ReadOnlyModeSvg from '../../assets/read_only_mode.svg';
import SandboxModeSvg from '../../assets/sandbox_mode.svg';
import { ProgressNoteContextProvider } from '../../context/ProgressNoteContext';
import EconsentTooltip from './EconsentTooltip';
import { EsourceInfoDialog } from './Dialog';
import { SaveProcedureAlertsDialogProps, SaveProcedureAlertsDialog } from './Dialog/SaveProcedure';
import { SaveAlertContextProvider } from '../../context/SaveAlertContext';
import {
  LockRequestInterface,
  acquireLock, deleteLock, fetchDataPoints, fetchLock, fetchSubjectVisitConfig,
} from '../../api/esourceService';
import RulesPreview from './RulesPreview';

const ProcedureRow = styled(Row)`
  height: calc(100vh - 39px);
`;
interface ProcedureNameContainerProps {
  showBottomBorder: boolean,
}
const ProcedureName = styled(Row) <ProcedureNameContainerProps>`
  font-size: 22px;
  font-weight: 500;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  padding-bottom: 16px;
  border-bottom: ${(props) => (props.showBottomBorder ? (`1px dotted${props.theme.palette.grey[500]}`) : 'none')};
`;
const ProcedureComponent = styled(Column)`
  width: 100%;
  overflow-x: scroll;

  .Link {
    color: ${(props) => props.theme.palette.linkText.main};
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.palette.linkText.dark};
      text-decoration: underline;
    }
  }

  button {
    font-weight: 500;
  }

  div.Openable {
    .RecordHeader {
      cursor: pointer;
      justify-content: space-between;
    }
    .Collapsible {
      height: auto;
    }

    &.Closed {

      .Collapsible {
        height: 0;
        overflow: hidden;
      }
    }
  }

  div.Closed {
    .Hideable {
      display: none;
    }
  }

  .SyntheticQuestionCheckbox {
    padding: 20px 5px;
  }

  .Grid, .MultiRecord {
    padding-top: 30px;
  }

  .MultiRecordBtns {
    margin-right: 3%;
    margin-top: 15px;
  }
`;

const StyledSpinner = styled('img')`
  text-align: center;
  margin: auto;
`;

const renderProcedureComponent = (
  {
    visitConfig,
    procedureId: currentProcedureId,
    saveProcedureAlertsDialogValues,
    setSaveProcedureAlertsDialogValues,
    initialAlert,
    setInitialAlert,
    subjectData,
  }: RenderProcedureProps,
) => {
  const { timeZone } = subjectData;

  const procedure = visitConfig.procedures.find(({ procedureId }) => procedureId === currentProcedureId)!;
  const { displayType, questions } = procedure;

  const procedureComponent = useMemo(() => {
    switch (displayType) {
      case ProcedureDisplayType.MULTI_RECORD:
        return <MultiRecord timeZone={timeZone} />;
      case ProcedureDisplayType.GRID:
        return (
          <Grid
            {...procedure}
            options={questions[0].answerOptions!.map((answerOption) => answerOption.text)}
          />
        );
      default:
        return (
          <NormalProcedure {...procedure} />
        );
    }
  }, [currentProcedureId, visitConfig]);

  return (
    <>
      {saveProcedureAlertsDialogValues.isOpen && (
        <SaveAlertContextProvider>
          <SaveProcedureAlertsDialog
            {...saveProcedureAlertsDialogValues}
            closeHandler={() => {
              setSaveProcedureAlertsDialogValues((prevState) => ({ ...prevState, isOpen: false }));
            }}
          />
        </SaveAlertContextProvider>
      )}
      {initialAlert
        && (
          <EsourceInfoDialog
            open={initialAlert !== undefined}
            closeHandler={() => setInitialAlert(undefined)}
            alertTitle={initialAlert.header || ''}
          >
            <p>{initialAlert.body}</p>
            {initialAlert?.bodyItems
              && (
                <ul>
                  {initialAlert?.bodyItems?.map((bodyItem: string) => (<li key={bodyItem}>{bodyItem}</li>))}
                </ul>
              )}
          </EsourceInfoDialog>
        )}
      {procedureComponent}
    </>
  );
};

export interface RenderProcedureProps extends ProcedureTabProps {
  saveProcedureAlertsDialogValues: SaveProcedureAlertsDialogProps,
  setSaveProcedureAlertsDialogValues: Dispatch<SetStateAction<SaveProcedureAlertsDialogProps>>,
  initialAlert: InitialAlertContent | undefined,
  setInitialAlert: Dispatch<SetStateAction<InitialAlertContent | undefined>>,
  subjectData: SubjectData,
}

export interface ProcedureTabProps {
  nextProcedureId?: string,
  initialProcedureStatus?: ProcedureStatus,
  procedureId: string,
  subjectData: SubjectData,
  visitConfig: VisitInterface,
  visitMode: VisitModeType,
  setHasUnsavedChanges: (newVal: boolean) => void,
  setProcedureId: Dispatch<SetStateAction<string | null>>,
  setProcedureStatuses: Dispatch<SetStateAction<ProcedureStatusInterface[]>>,
  setShowCompleteVisitPage: Dispatch<SetStateAction<boolean>>,
  setVisitConfig: Dispatch<SetStateAction<VisitInterface | undefined>>,
  subjectHeaderHeight: number,
}

interface FetchLockProps {
  procedureId: string,
  procedureReadOnly: boolean,
  studyId: string,
  subjectId?: string,
  visitId?: string,
}
/**
 * Fetch or acquire the procedure lock depending on if this procedure is read only. If we cannot successfully
 * acquire the lock, pop an error message with the name of the person who has it and set the procedure to
 * read only in the visit config. If the component becomes unmounted while we're asyncronously fetching things
 * from the server, do not attempt to update state.
 * @param FetchLockProps
 * @returns The lock that exists on this procedure
 */
export async function _doFetchOrAcquireLock({ // Only exported for testing purposes
  procedureId, procedureReadOnly, studyId, subjectId, visitId,
}: FetchLockProps): Promise<Lock> {
  const { currentUser } = getAuth();
  const { uid } = currentUser || {};
  const safelyGetLock = async (lockFunction: (lockRequest: LockRequestInterface) => Promise<Lock>, lock?: Lock): Promise<Lock> => {
    try {
      return await lockFunction({
        subjectId,
        studyId: studyId!,
        procedureId,
        visitId,
        lock,
      });
    } catch ({ message }: any) {
      try {
        return JSON.parse(message as string) as Lock;
      } catch (jsonError) {
        // We decided if there's some sort of redis issue, we'd keep the procedures unlocked
        return { lockMessage: 'Could not fetch lock' };
      }
    }
  };
  const fetchedLock: Lock = procedureReadOnly ? await safelyGetLock(fetchLock, undefined) : await safelyGetLock(acquireLock, {
    userId: uid,
    lockMessage: 'Locking procedure',
  });

  return fetchedLock;
}

const _makeCurrentProcedureReadOnlyIfNecessary = (lock: Lock | null, visitConfig: VisitInterface, currentProcedureId: string): void => {
  const { currentUser } = getAuth();
  const { uid } = currentUser || {};
  const { acquireTime, userId: userIdOnLock } = lock || {};
  if (!acquireTime || userIdOnLock !== uid) {
    const index = visitConfig.procedures.findIndex(({ procedureId: pId }) => pId === currentProcedureId);
    if (index > -1) {
      visitConfig.procedures[index].procedureLocked = true;
    }
  }
};

interface FetchVisitConfigProps {
  visitMode: VisitModeType | undefined,
  procedureId: string,
  studyId: string,
  subjectId?: string,
  visitId?: string,
  procedureTemplateId?: string,
  setProcedureId: Dispatch<SetStateAction<string | null>>,
}
/**
 * Fetch the most up to date visit config.  If the component becomes unmounted while we're asyncronously
 * fetching things from the server, do not attempt to update state.
 * @param FetchVisitConfigProps
 * @returns The visit config necessary to render this procedure
 */
export async function _doFetchVisitConfig({ // Only exported for testing purposes
  visitMode, procedureId, studyId, subjectId, visitId, procedureTemplateId, setProcedureId,
}: FetchVisitConfigProps): Promise<VisitInterface> {
  const newVisitConfig: VisitInterface = await fetchSubjectVisitConfig(visitMode, studyId!, subjectId, visitId, procedureId, procedureTemplateId);

  const { procedures: newProcedures } = newVisitConfig || {};
  const newProcedureConfig = newProcedures?.find(({ procedureId: pId }) => procedureId === pId);
  if (!newProcedureConfig) {
    // We need to be careful - if the selected procedure no longer exists in the config,
    // default to the first procedure (this is the same as the old siteapp behavior).
    const firstProcedureId = newProcedures?.[0]?.procedureId;
    if (firstProcedureId) {
      setProcedureId(firstProcedureId);
    } else {
      throw new Error('Could not find any procedures in new config that was supplied');
    }
  }

  return newVisitConfig;
}

interface FetchDataPointsProps {
  procedureId: string,
  procedureReadOnly: boolean,
  studyId: string,
  subjectId?: string,
  visitId?: string,
  visitMode: VisitModeType,
}
/**
 * Simple routine to just fetch all the datapoints that exist on the currently specified procedure. If the
 * component becomes unmounted while we're asyncronously fetching things from the server, do not attempt to
 * update state.
 * @param FetchDataPointsProps
 * @returns The datapoints for this procedure
 */
export async function _doFetchDataPoints({ // Only exported for testing purposes
  procedureId, studyId, subjectId, visitId, visitMode,
}: FetchDataPointsProps): Promise<Array<DataPoint>> {
  const fetchedDataPoints = await fetchDataPoints({
    visitMode,
    studyId: studyId!,
    subjectId,
    visitId,
    procedureId,
  });

  return fetchedDataPoints;
}

const _getVisitIdForLocking = (carryForwardType: CarryForwardType | undefined, visitId: string | undefined): string | undefined => (carryForwardType === CarryForwardType.PERMANENT ? undefined : visitId);

/**
 * Generic Procedure component that handles rendering the different procedure types.
 * @returns
 */
export default function Procedure(props: ProcedureTabProps) {
  const {
    nextProcedureId,
    procedureId: currentProcedureId,
    initialProcedureStatus = ProcedureStatus.NOT_STARTED,
    setHasUnsavedChanges,
    setProcedureId,
    setProcedureStatuses,
    setShowCompleteVisitPage,
    setVisitConfig,
    subjectData,
    visitConfig,
    visitMode,
    subjectHeaderHeight,
  } = props;
  const mountedRef = useRef(true);
  const { subjectId } = subjectData;

  const [saveProcedureAlertsDialogValues, setSaveProcedureAlertsDialogValues] = useState<SaveProcedureAlertsDialogProps>({
    isOpen: false, deletingRecord: false, missingAnswers: [], changedAnswers: [], nothingEverAnswered: true,
  });

  const procedure = visitConfig.procedures.find(({ procedureId: pId }) => pId === currentProcedureId)!;
  const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lockedAlert, setLockedAlert] = useState<string>('');
  const [userLock, setUserLock] = useState<Lock>();
  const [initialAlert, setInitialAlert] = useState<InitialAlertContent | undefined>(undefined);
  const {
    allowSkip, carryForwardType, hasEconsent, instructions, name, readOnly: procedureReadOnly, status, procedureLocked, initialAlertContent, rules,
  } = procedure;
  const { t } = useTranslation();
  const { studyId, visitId, procedureTemplateId } = useParams();
  const readOnlyMode = isAnyReadOnlyMode(visitMode) || procedureReadOnly || !!procedureLocked;
  const instructionsHeader = (
    <Row className="InstructionsHeader">
      <h3>{t('Procedure.Instructions')}</h3>
      {visitMode === VisitModeType.DEFAULT && hasEconsent && <EconsentTooltip disabled={readOnlyMode} subjectData={subjectData} visitConfig={visitConfig} />}
    </Row>
  );

  useEffect(() => {
    const initializeProcedure = async (): Promise<void> => {
      const { currentUser } = getAuth();
      const { uid } = currentUser || {};
      const dataPointsPromise = _doFetchDataPoints({
        procedureId: currentProcedureId,
        procedureReadOnly,
        studyId: studyId!,
        subjectId,
        visitId,
        visitMode,
      });
      const lockPromise = _doFetchOrAcquireLock({
        procedureId: currentProcedureId,
        procedureReadOnly,
        studyId: studyId!,
        subjectId,
        visitId: _getVisitIdForLocking(carryForwardType, visitId),
      });
      const visitConfigPromise = _doFetchVisitConfig({
        visitMode,
        procedureId: currentProcedureId,
        studyId: studyId!,
        subjectId,
        visitId,
        procedureTemplateId,
        setProcedureId,
      });

      const [fetchedDataPoints, fetchedLock, fetchedVisitConfig] = await Promise.all([dataPointsPromise, lockPromise, visitConfigPromise]);
      const { procedures: newProcedures } = fetchedVisitConfig;

      const newProcedure = newProcedures.find(({ procedureId: pId }) => pId === currentProcedureId);
      const { carryForwardType: newCarryForwardType, readOnly: newProcedureReadOnly } = newProcedure || {};
      let currentLock = fetchedLock;
      if (procedureReadOnly && !newProcedureReadOnly) {
        // The procedure used to be read only so we didn't attempt to acquire the lock. Now that the
        // procedure isn't read only anymore, we need to attempt to acquire the lock for this procedure
        currentLock = await _doFetchOrAcquireLock({
          procedureId: currentProcedureId,
          procedureReadOnly: !!newProcedureReadOnly,
          studyId: studyId!,
          subjectId,
          visitId: _getVisitIdForLocking(newCarryForwardType, visitId),
        });
      } else if (!procedureReadOnly && newProcedureReadOnly) {
        await deleteLock({
          subjectId,
          studyId: studyId!,
          procedureId: currentProcedureId,
          visitId: _getVisitIdForLocking(carryForwardType, visitId),
        });
        currentLock = { lockMessage: 'Just deleted the lock because this procedure is now read only' };
      }
      _makeCurrentProcedureReadOnlyIfNecessary(currentLock, fetchedVisitConfig, currentProcedureId);

      if (!mountedRef.current) return;
      // If there's a user with the lock and it doesn't match the current user, then show the locked alert
      const { userId, userName } = currentLock;
      if (userId && userId !== uid) {
        setLockedAlert(t('Procedure.Dialog.Locked.Message', { userName }));
      } else {
        if (userId === uid) {
          setUserLock(currentLock);
        }
        setLockedAlert('');
      }
      setVisitConfig(fetchedVisitConfig);
      setDataPoints(fetchedDataPoints);
      setIsLoading(false);
    };

    initializeProcedure();
  }, [currentProcedureId]);

  useEffect(() => {
    const { userId: lockUserId } = userLock || {};
    const unload = () => {
      // Clean up the lock if the current user has it on this procedure
      if (lockUserId) {
        deleteLock({
          subjectId,
          studyId: studyId!,
          procedureId: currentProcedureId,
          visitId: _getVisitIdForLocking(carryForwardType, visitId),
        });
      }
    };

    // Blindly send the lock deletion request if they just close the page
    window.onunload = unload;
    return (() => {
      // If this component is unmounted, delete the lock
      unload();
    });
  }, [userLock]);

  useEffect(() => {
    // Keep the initial alert content up to date
    setInitialAlert(initialAlertContent);
  }, [isLoading]);

  // This useEffect is to update our ref on unmount which we later use to determine
  // if we should ignore the results of long-running asynchronous tasks
  useEffect(() => () => {
    mountedRef.current = false;
  }, []);

  return (
    <>
      {lockedAlert && (
        <CrioAlertDialog
          type="Info"
          open={!!lockedAlert}
          onClose={() => {
            setLockedAlert('');
          }}
          fullWidth={false}
          disablePortal
          sx={{ width: '50%', margin: 'auto' }}
        >
          <div data-testid="lockErrorMessage">{lockedAlert}</div>
        </CrioAlertDialog>
      )}
      {!isAnyProcedureMode(visitMode) && (visitMode === VisitModeType.SANDBOX || readOnlyMode) && (
        <Row className={`VisitModeBar ${readOnlyMode ? 'ReadOnly' : 'Sandbox'}`}>
          <Row className="VisitMode">
            <img
              src={readOnlyMode ? ReadOnlyModeSvg : SandboxModeSvg}
              alt="Mode"
            />
            <div>{readOnlyMode ? t('Procedure.Mode.READ-ONLY') : t('Procedure.Mode.SANDBOX')}</div>
          </Row>
        </Row>
      )}
      <ProcedureRow className="ProcedureContent CenterPageBackground">
        <ProgressNoteContextProvider
          procedureId={currentProcedureId}
          visitMode={visitMode}
          subjectData={subjectData}
          visitConfig={visitConfig}
          procedureName={procedure.name}
        >
          <ProcedureContextProvider
            isLoading={isLoading}
            dataPoints={dataPoints}
            initialProcedureStatus={initialProcedureStatus}
            nextProcedureId={nextProcedureId}
            procedureId={currentProcedureId}
            readOnly={readOnlyMode}
            setProcedureId={setProcedureId}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setShowCompleteVisitPage={setShowCompleteVisitPage}
            subjectData={subjectData}
            setProcedureStatuses={setProcedureStatuses}
            setSaveProcedureAlertsDialogValues={setSaveProcedureAlertsDialogValues}
            visitConfig={visitConfig}
            visitMode={visitMode}
          >
            {isLoading && (
              <StyledSpinner data-testid="loadingSpinner" alt="Loading..." src={spinner} height="42px" width="42px" />
            )}
            <ProcedureComponent style={isLoading ? { display: 'none' } : {}} className="CenterPagePadding">
              <ProcedureName showBottomBorder={!instructions}>{name}</ProcedureName>
              <InstructionText instructions={instructions!} header={instructionsHeader} allowSkip={allowSkip} procedureStatus={status} readOnly={readOnlyMode} truncate={false} />
              {renderProcedureComponent({
                ...props, setSaveProcedureAlertsDialogValues, saveProcedureAlertsDialogValues, initialAlert, setInitialAlert, subjectData,
              })}
              {visitMode === VisitModeType.PROCEDURE_PREVIEW
                && <RulesPreview rules={rules} />}
            </ProcedureComponent>

            {!(VisitModeType.SUBJECT === visitMode) && !isAnyProcedureMode(visitMode) && (
              <Slideout
                procedureName={name}
                isVisitLevel={false}
                subjectHeaderHeight={subjectHeaderHeight}
                timeZone={subjectData.timeZone}
              />
            )}
          </ProcedureContextProvider>
        </ProgressNoteContextProvider>
      </ProcedureRow>
    </>
  );
}

Procedure.defaultProps = {
  nextProcedureId: null,
  initialProcedureStatus: [],
};
