/* eslint-disable no-param-reassign */

import {
  AnswerType, CarryForwardType, ProcedureStatus, VisitStatus,
} from '../enums';
import {
  DataPoint, ProcedureStatusInterface, SubjectData, VisitInterface,
} from '../types';
import { isVisitDataPoint } from './esourcePredicateUtil';
import { getSkipProcedureStatusTypes } from './procedureStatusUtil';
import { getProcedureTypeFromDisplayType } from './procedureUtils';

/**
 * Get a Data Point that is based on the given Data Point
 * and enriched using the given Procedure Interface for Procedure-level attributes
 * @param dataPoint           DataPoint to copy from
 * @param procedureInterface  ProcedureInterface to use for enrichment
 * @return                    DataPoint built using the given arguments
 */
const getProcedureLevelDataPointFromBase = (dataPoint: DataPoint, procedureStatus: ProcedureStatusInterface): DataPoint => ({
  ...dataPoint,
  procedure_id: procedureStatus.procedureId,
  procedure_status: procedureStatus.status,
});

/**
 * Get those Procedure Statuses that are skipped
 * @param procedureStatuses   Array<ProcedureStatusInterface> to filter
 * @return                    Array<ProcedureStatusInterface> containing only those w/ skipped Statuses
 */
const getSkipProcedureStatuses = (procedureStatuses: Array<ProcedureStatusInterface>): Array<ProcedureStatusInterface> => {
  const skipProcedureStatusTypes: Array<ProcedureStatus> = getSkipProcedureStatusTypes();
  return procedureStatuses
    .filter((procedureStatus: ProcedureStatusInterface) => skipProcedureStatusTypes.includes(procedureStatus.status));
};

const applySubjectDataToDataPoint = (dataPoint: DataPoint, subjectData: SubjectData) => {
  if (!subjectData) return;

  const {
    subjectId, subjectStatus, trialId, armId, armName, organizationId, protocolNumber, siteId,
  } = subjectData;

  dataPoint.subject_id = subjectId;
  dataPoint.subject_status = subjectStatus;
  dataPoint.organization_id = organizationId;
  dataPoint.protocol_number = protocolNumber;
  dataPoint.trial_id = trialId;
  dataPoint.arm_id = armId;
  dataPoint.arm_name = armName;
  dataPoint.site_id = siteId;
};

const applyVisitConfigToDataPoint = (dataPoint: DataPoint, visitConfig: VisitInterface, carryForwardType: CarryForwardType) => {
  const {
    name, number, order, type, templateLevel, versionId, versionName, visitId,
  } = visitConfig;

  // Permanent records do not get visit information except for if you're answering has_changes (which is a visit level question)
  if (isVisitDataPoint({ carryForwardType, dataPoint, visitId })) {
    dataPoint.visit_id = visitId;
    dataPoint.visit_name = name;
    dataPoint.visit_number = number;
    dataPoint.visit_order = order;
    dataPoint.visit_type = type;
    dataPoint.visit_template_level = templateLevel;
    dataPoint.visit_version_id = versionId;
    dataPoint.visit_version_name = versionName;
  } else {
    dataPoint.visit_id = undefined;
  }
};

/**
 * Get a base ancillary data point
 */
const getAncillaryDataPoint = (
  studyId: string,
  procedureId: string | undefined | null,
  currentUser: any,
  subjectData: SubjectData,
  visitConfig: VisitInterface,
): DataPoint => {
  const dataPoint: DataPoint = {
    study_id: studyId!,
    answer_user: currentUser?.displayName || '',
    answer_user_id: currentUser?.uid,
    answer_completed_date: new Date().getTime(),
    visit_status: VisitStatus.PAUSED,
    answer_type: AnswerType.ANCILLARY,
  } as DataPoint;

  // Currently can't add progress notes to perm procedures, so just assume carry forward no
  applyVisitConfigToDataPoint(dataPoint, visitConfig, CarryForwardType.NO);
  applySubjectDataToDataPoint(dataPoint, subjectData);

  // Visit status and visit-leve progress note ancillary datapoints will not have procedures tied to them
  if (procedureId) {
    const focusedProcedure = visitConfig?.procedures
      ?.filter(({ procedureId: configProcedureId }) => configProcedureId === procedureId)[0];
    const { carryForwardType } = focusedProcedure || {};

    dataPoint.procedure_id = procedureId;
    dataPoint.procedure_name = focusedProcedure?.name;
    dataPoint.procedure_order = focusedProcedure?.order;
    dataPoint.procedure_type = getProcedureTypeFromDisplayType(focusedProcedure?.displayType, carryForwardType);
  }

  return dataPoint;
};

interface PartitionedDataPointsBySpecificity {
  visitLevelDataPoints: DataPoint[],
  procedureLevelDataPoints: DataPoint[],

}
const partitionDataPointsBySpecificity = (dataPoints: DataPoint[]): PartitionedDataPointsBySpecificity => {
  const visitLevelDataPoints: DataPoint[] = [];
  const procedureLevelDataPoints: DataPoint[] = [];

  dataPoints.forEach((dataPoint) => {
    const { procedure_id } = dataPoint;
    if (procedure_id) {
      procedureLevelDataPoints.push(dataPoint);
    } else {
      visitLevelDataPoints.push(dataPoint);
    }
  });

  return {
    visitLevelDataPoints,
    procedureLevelDataPoints,
  };
};

/**
 * Given an answer and a list of the available answer options, insert or remove the answer and return a resulting string to update the DataPoint with.
 * NOTE: only use this with multiselects since the /n character is the delimiter used for them
 * @param currentAnswer the answer that is changing
 * @param newAnswer     the newly selected/deselected answer value
 * @param answerOptions the list of available answers (in display order)
 * @param toggleOn      whether the answer is turning on/off
 */
const updateAndSortMultiSelectAnswers = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  currentAnswer: string = '',
  newAnswer: string,
  answerOptions: string[],
  toggleOn: boolean,
): string => {
  const answerArray = (currentAnswer).split('\n');
  if (toggleOn && !answerArray.includes(newAnswer) && answerOptions.includes(newAnswer)) {
    // Condition to add a new answer
    answerArray.push(newAnswer);
  } else if (!toggleOn && answerArray.includes(newAnswer)) {
    // Condition to remove the answer
    const existingIndex = answerArray.indexOf(newAnswer);
    answerArray.splice(existingIndex, 1);
  }

  answerArray.sort((a, b) => answerOptions.indexOf(a) - answerOptions.indexOf(b));
  return answerArray.filter((a) => !!a).join('\n');
};

export {
  applyVisitConfigToDataPoint,
  applySubjectDataToDataPoint,
  getProcedureLevelDataPointFromBase,
  getSkipProcedureStatuses,
  getAncillaryDataPoint,
  partitionDataPointsBySpecificity,
  updateAndSortMultiSelectAnswers,
};
